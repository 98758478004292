import styled from "styled-components"
import { Link } from "gatsby"
import { device } from "breakpoints"
import { MainColumn } from "components/MainColumn"
import Sticky from "react-sticky-el"

const navItemHorizontalSpacing = "20px"

export const NavBarContainer = styled(MainColumn)`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-top: 32px;
  z-index: 10000000;

  @media ${device.desktop} {
    padding: 30px 0;
  }

  @media ${device.large} {
  }
`

export const LogoWrapper = styled(Link)`
  display: flex;
  align-items: center;
  height: auto;
  z-index: 4;

  @media ${device.desktop} {
    transform: scale(1);
    width: 117px !important;
    /* width: 127px !important; */
  }
  /* removed this once changed the logo back to voice */
  /* @media ${device.large} {
    width: 147px !important;
  } */

  > svg,
  > img {
    transition: 350ms ease-out;
    transform-origin: left;
    /* height: 39px;
    width: 140px; */
    height: 24px;
    width: 94px;
    display: block;

    @media ${device.desktop} {
      height: 30px;
      width: 127px;
    }
    /* removed this once changed the logo back to voice */
    /* @media ${device.large} {
      height: 30px;
      width: 147px;
    } */
  }
`

export const NavWrapper = styled.div`
  display: flex;
`

export const Nav = styled.nav`
  position: absolute;
  display: flex;
  top: 0;
  transform: translateX(-50%);
  left: 50%;
  margin: 0;
  background: ${props => props.theme.blue};
  width: 100vw;
  opacity: ${props => (props.isNavShown ? "1" : "0")};
  pointer-events: ${props => (props.isNavShown ? "all" : "none")};
  z-index: 3;
  padding-top: 94px;
  padding-bottom: 137px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.desktop} {
    flex-direction: row;
    transform: none;
    opacity: 1;
    padding: 0;
    left: auto;
    position: relative;
    width: 100%;
    bottom: auto;
    background: none;
    transform: none;
    justify-content: flex-end;
  }

  .social-links {
    position: absolute;
    bottom: 62px;
    @media ${device.desktop} {
      display: none;
    }
  }
`

export const NavList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0px 30px;

  @media ${device.desktop} {
    flex-direction: row;
    border: none;
  }
`

export const NavListItem = styled.li`
  width: 100%;

  @media ${device.desktop} {
    width: auto;
  }
  a {
    /* padding: 0px 30px; */
    display: block;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    color: white;
    letter-spacing: 0.78px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    @media ${device.desktop} {
      border: none;
      text-align: center;
      pointer-events: all;
      font-size: 13px;
      padding: 7px 18px;
    }

    @media ${device.large} {
      padding: 7px ${navItemHorizontalSpacing};
      font-size: 14px;
    }
  }

  span {
    position: relative;
    width: 100%;
    display: block;
    padding: 18px 0;

    ::before {
      content: "";
      position: absolute;
      left: 0;
      opacity: 0;
      height: 2px;
      width: 100%;
      bottom: -6px;
      transition: 200ms;
      background: ${props => props.theme.green};
    }
    @media ${device.desktop} {
      padding: 0;
    }
  }

  @media ${device.desktop} {
    padding: 0;
    border: none;
    display: inline;
    width: auto;
    :hover {
      span::before {
        opacity: 1;
        bottom: -3px;
      }
    }
  }
`

export const QuickSearchWrapper = styled(Link)`
  display: flex;
  align-items: center;
  max-height: 60px;
  height: 100%;
  opacity: 1;
  transition: 110ms;
  z-index: 3;

  @media ${device.desktop} {
    padding: 0;
  }

  :hover {
    opacity: 0.7;
  }
  svg {
    height: 34px;
    width: 34px;
    @media ${device.desktop} {
      height: 40px;
      width: 40px;
    }
  }
`

export const Clickable = styled.div`
  display: block;
  height: 100%;
  width: 100%;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.desktop} {
    display: none;
  }
`

export const HamburgerWrapper = styled.div`
  display: inline-block;
  width: 27px;
  height: 20px;
  position: relative;

  cursor: pointer;
`

export const FirstLine = styled.span`
  display: block;
  height: 2px;
  width: 100%;
  transition: 0.4s ease-out;
  background: white;
  position: absolute;
  transform: ${props =>
    props.isNavShown
      ? "translate3d(0, 9px, 0) rotate(45deg)"
      : "translate3d(0, 0, 0) rotate(0deg)"};
`

export const SecondLine = styled(FirstLine)`
  transition: 0.1s ease-out;
  opacity: ${props => (props.isNavShown ? 0 : 1)};
  top: 9px;
`

export const ThirdLine = styled(FirstLine)`
  transform: ${props =>
    props.isNavShown
      ? "translate3d(0, 9px, 0) rotate(-45deg)"
      : "translate3d(0, 18px, 0) rotate(0deg)"};
  display: block;
`

export const IconsContainer = styled.div`
  display: flex;
  gap: 16px;
`

export const NavSticky = styled(Sticky).withConfig({
  shouldForwardProp: prop => !["noImg"].includes(prop),
})`
  z-index: 100;
  position: relative;
  background: ${props => props.noImg && props.theme.blue};
  padding-bottom: ${props => props.noImg && "30px"};

  > div {
    transition: background 210ms ease-out;
  }

  @media ${device.desktop} {
    padding-bottom: ${props => props.noImg && 0};
  }

  /* extended blue background */
  :before {
    content: "";
    background: ${props => props.theme.blue};
    position: absolute;
    top: 0;
    left: 0;
    height: 500px;
    opacity: 0;
    width: 100%;
    transform: translateY(-100%);
    transition: opacity 210ms ease-out;
  }

  .sticky {
    background: ${props => props.theme.blue};
    position: relative;
    z-index: 1000;

    :before {
      opacity: 1;
    }
    ${LogoWrapper} {
      width: 117px;
      > svg,
      > img {
        transform: scale(0.8);
      }
    }
  }
`
