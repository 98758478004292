import { useStaticQuery, graphql } from "gatsby"

export const GetLocationsData = () => {
  const imgData = useStaticQuery(
    graphql`
      query {
        auckland: file(relativePath: { eq: "location_map.png" }) {
          childImageSharp {
            fluid(maxWidth: 140, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }

        melbourne: file(relativePath: { eq: "melbourne-map@2x.png" }) {
          childImageSharp {
            fluid(maxWidth: 140, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  return [
    {
      title: "Auckland",
      address: `A1/ 1 Beresford Square, Auckland Central 1010`,
      person: "Neil Sparksman",
      mobile: "+64 21 334 747",
      telephone: "+64 9 373 5086",
      email: "neil@voice.co.nz",
      img: imgData.auckland.childImageSharp.fluid,
    },
    // {
    //   title: "Melbourne",
    //   address: `425 Smith St, Collingwood<br/>
    //   Melbourne<br/>
    //   VIC 3065 Australia`,
    //   person: "Fiona Lopez",
    //   mobile: "+61 416 147 972",
    //   telephone: "+61 3 9113 7914",
    //   email: "fiona@voicebrandagency.com.au",
    //   img: imgData.melbourne.childImageSharp.fluid,
    // },
  ]
}
