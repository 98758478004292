import styled from "styled-components"
import { device } from "breakpoints"

export const LocationsContainer = styled.div`
  padding: 40px 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 360px;
  position: relative;

  @media ${device.tablet} {
    min-width: 314px;
    width: 41%;
    padding: 0;
  }

  @media ${device.desktop} {
    min-width: auto;
    max-width: 100%;
  }
  :after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    width: 1000px;
    background: white;
    opacity: 0.3;

    @media ${device.tablet} {
      display: none;
    }
  }
`

export const LocationInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

     :first-of-type {
      padding-right: 15px;
    }

    :last-of-type {
      padding-left: 15px;
    }


  h4 {
    color: ${props => props.theme.green};
    font-size: 14px;
    margin-bottom: 15px;

    @media ${device.desktop} {
      font-size: 15px;
      margin-bottom: 10px;
    }
  }

  p.address {
    margin-bottom: 15px;
    font-weight: 400;
    color: white;
    font-size: 12px;
    letter-spacing: 0.33px;

    @media ${device.desktop} {
      margin-bottom: 16px;
      font-size: 14px;c
    }
  }

  /* span.name {
    font-weight: 500;
    margin-bottom: 5px;
    color:white;
  } */

  div.number {
    position: relative;
    font-size: 12px;
    a,
    p {
      font-weight: 500;
      margin-left: 13px;
      letter-spacing: 0.33px;
      color: white;
      display: inline;

      :before {
        display: none;
      }
    }

    span {
      font-weight: 500;
      color: ${props => props.theme.green};
    }
  }

  a.email {
    font-weight: 500;
    margin-top: 14px;
    color: ${props => props.theme.green};
    font-size: 12px;
    display: inline;
    letter-spacing: 0.33px;

    @media ${device.desktop} {
      font-size: 14px;
    
    }
  }

  a {
    position: relative;
    display: inline-block;
    :before {
      content: "";
      width: 100%;
      height: 2px;
      opacity: 0;
      transition: 70ms;
      background: ${props => props.theme.green};
      position: absolute;
      bottom: -1px;
      left: 0;
    }

    :hover {
      :before {
        opacity: 1;
      }
    }
  }
`
