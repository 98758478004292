import React from "react"
import PropTypes from "prop-types"
import "./layout.css"
import theme from "../utils/theme.js"
import { ThemeProvider } from "styled-components"
import { Header } from "./Header"
import { Footer } from "./Footer"
import "../fonts/NHaasGroteskDSPro/stylesheet.css"

const Layout = ({
  children,
  internal = true,
  title,
  home,
  headerImg,
  excerpt,
  pages,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <div className="app-wrapper">
        <Header
          internal={internal}
          title={title}
          home={home}
          img={headerImg}
          excerpt={excerpt}
          pages={pages}
        />
        <main>{children}</main>
        <Footer internal={internal} />
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
