import styled from "styled-components"
import { device } from "breakpoints"
import { MainColumn } from "components/MainColumn"

export const FooterContainer = styled.div`
  height: 410px;
  background: ${props => props.theme.blue};
  height: ${props => props.internal && "auto"};
  @media ${device.tablet} {
    height: 300px;
    height: ${props => props.internal && "auto"};
    padding: ${props => props.internal && "60px 0"};
  }

  @media ${device.desktop} {
    padding: ${props => props.internal && "94px 0"};
  }

  a.madeByAvenue {
    color: white;
    margin-top: 30px;
    font-size: 12px;
    margin-top: ${props => props.internal && "-16px"};
    margin-bottom: 50px;

    :hover {
      background-image: linear-gradient(to right, #00ff00, #00ff00);
      background-position: 0 100%;
      background-repeat: no-repeat;
      background-size: 100% 2px;
    }

    @media ${device.tablet} {
      position: absolute;
      right: 0;
      bottom: 70px;
      bottom: ${props => props.internal && "5px"};
      margin-bottom: 0;
      margin-top: 0;
    }
  }
`

export const FooterMainColumn = styled(MainColumn)`
  height: 100%;
  padding-top: 50px;
  align-items: center;
  overflow: hidden;
  position: relative;

  @media ${device.tablet} {
    flex-direction: row;
    overflow: visible;

    padding-top: 0;
    justify-content: space-between;
    align-items: ${props => props.internal && "flex-start"};
  }

  svg.years {
    height: 93px;
    width: 54px;
    margin-bottom: 40px;
    margin-bottom: ${props => props.internal && "55px"};

    @media ${device.tablet} {
      height: 102px;
      width: 59px;
      margin-bottom: 0px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);

      ${props =>
        props.internal &&
        `
        position:static;
        left:auto;
        top:auto;
        transform:none;
        margin-top:-10px;
      `};
    }
  }

  .social-links {
    @media ${device.tablet} {
      justify-content: flex-end;
    }
  }

  span.copyright {
    margin-bottom: 25px;
    font-size: 12px;
    color: white;
    font-weight: 500;
    @media ${device.tablet} {
      margin-bottom: 0;
    }
  }

  > span.copyright {
    margin-top: 30px;
    margin-bottom: ${props => props.internal && "30px"};

    @media ${device.tablet} {
      display: none;
    }
  }
`

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: ${props => props.internal && "40px"};
  border-bottom: ${props =>
    props.internal && "1px solid rgba(255,255,255, .3)"};
  @media ${device.tablet} {
    width: auto;
    flex-direction: row;
    border: none;
    width: 310px;
    ${props =>
      props.internal &&
      `
      padding-bottom: 0;
      height: 100%;
      max-width:200px;
      margin-top:-17px;
      flex-wrap: wrap;

    `};
  }

  svg.monogram {
    height: 42px;
    width: 42px;
    margin-bottom: 17px;
    margin-bottom: ${props => props.internal && "20px"};

    @media ${device.tablet} {
      height: 48px;
      width: 48px;
      margin-right: 15px;
      margin-right: ${props => props.internal && "26px"};
      margin-bottom: 0;
    }
  }

  span.copyright {
    display: ${props => props.internal && "none"};

    @media ${device.tablet} {
      display: block;
      margin-top: ${props => props.internal && "98px"};
    }

    @media ${device.desktop} {
      margin-top: ${props => props.internal && "106px"};
    }
  }

  > .social-links {
    @media ${device.tablet} {
      width: 115px;
    }
  }
`
