import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  LogoWrapper,
  NavBarContainer,
  NavWrapper,
  Nav,
  NavList,
  QuickSearchWrapper,
  IconsContainer,
  NavSticky,
} from "./style"
import NavItem from "./navItem"
import Hamburger from "./hamburger"
import { Icon } from "utils/helpers/getIcon"
import { ReactComponent as Logo } from "images/icons/voice-logo.svg"
// import { ReactComponent as Logo } from "images/icons/tuapapa-logo.svg"
import { SocialLinks } from "../SocialLinks"
import useMedia from "hooks/useMedia.js"

const NavBar = ({ noImg }) => {
  const [isNavShown, setNavShown] = useState(false)
  const isMobile = useMedia(["(max-width: 1023px)"], [true], false)

  const homeData = useStaticQuery(
    graphql`
      {
        wpgraphql {
          pages(
            where: { notIn: "1822", orderby: { field: MENU_ORDER, order: ASC } }
          ) {
            nodes {
              title
              link
              slug
            }
          }
        }
      }
    `
  )

  const pages = homeData.wpgraphql.pages

  const toggleShowNav = () => {
    setNavShown(!isNavShown)
  }

  return (
    <NavSticky disabled={isMobile} noImg={noImg}>
      <NavBarContainer>
        <LogoWrapper to="/">
          <Logo />
        </LogoWrapper>
        <NavWrapper>
          <Nav isNavShown={isNavShown}>
            <NavList>
              {pages.nodes.map((page, index) => {
                if (
                  page.title === "Insight" ||
                  page.title === "Strategy" ||
                  page.title === "Design" ||
                  page.title === "Activation" ||
                  page.title === "Our journey" ||
                  page.title === "Contact"
                )
                  return null

                const changeTitle = title => {
                  if (title === "What we do") {
                    return "Our Brand Process"
                  } else if (title === "Our senior team") {
                    return "Our Team"
                  } else return title
                }
                return (
                  <NavItem
                    label={changeTitle(page.title)}
                    path={page.link}
                    key={index}
                  />
                )
              })}
            </NavList>

            <SocialLinks />
          </Nav>
        </NavWrapper>
        <IconsContainer>
          <QuickSearchWrapper to="/contact">
            <Icon name="contact" />
          </QuickSearchWrapper>
          <QuickSearchWrapper to="/search">
            <Icon name="search" />
          </QuickSearchWrapper>
          <Hamburger isNavShown={isNavShown} toggleShowNav={toggleShowNav} />
        </IconsContainer>
      </NavBarContainer>
    </NavSticky>
  )
}

export default NavBar
