import React from "react"

import { FooterContainer, LeftWrapper, FooterMainColumn } from "./style"
import { ReactComponent as Monogram } from "images/icons/monogram.svg"
import { ReactComponent as Years } from "images/icons/25-years.svg"
import { SocialLinks } from "components/SocialLinks"
import { Locations } from "components/Locations"

const Footer = ({ internal }) => {
  return (
    <FooterContainer internal={internal}>
      <FooterMainColumn internal={internal}>
        <LeftWrapper internal={internal}>
          <Monogram className="monogram" />
          {internal && <SocialLinks internal={internal} />}

          <span className="copyright"> © {new Date().getFullYear()} Voice Brand Agency</span>
        </LeftWrapper>

        {internal && <Locations />}

        {internal && (
          <span className="copyright"> © {new Date().getFullYear()} Voice Brand Agency</span>
        )}

        <Years className="years" />
        {!internal && <SocialLinks internal={internal} />}
        
        <a
          href="https://avenue.design/"
          target="_blank"
          rel="noopener noreferrer"
          className="madeByAvenue"
        >
          Made by Avenue
        </a>
      </FooterMainColumn>
    </FooterContainer>
  )
}
export default Footer
