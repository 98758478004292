import styled from "styled-components"

export const SocialLinksContainer = styled.div`
  display: inline-flex;
  align-items: center;

  > a {
    margin-right: 30px;
    opacity: 1;

    &.instagram {
      height: 22px;
      width: 22px;
    }

    &.linked-in {
      height: 20px;
      width: 20px;
    }

    &.facebook {
      height: 20px;
      width: 12.5px;
    }

    :last-of-type {
      margin: 0;
    }

    svg {
      width: 100%;
      height: 100%;
      display: block;
    }

    :hover {
      opacity: 0.6;
      transition: 170ms;
    }
  }
`
