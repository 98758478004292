const primaryColor = "#0000FF"
const secondaryColor = "#00FF00"
const white = "#FFFFFF"

const theme = {
  primaryColor: primaryColor,
  linkColor: primaryColor,
  btnColor: primaryColor,
  btnHoverColor: secondaryColor,
  btnTextColor: white,
  mobileGutter: 30,
  desktopGutter: 45,
  mainMaxWidth: 1160,
  blue: primaryColor,
  green: secondaryColor,
  mobileGap: 10,
  tabletGap: 25,
  desktopGap: 45,
  largeGap: 60,
  flexboxgrid: {
    breakpoints: {
      xs: 0, // em
      sm: 48, // em
      md: 64, // em
      lg: 80, // em
    },
  },
}

export default theme
