import React from "react"

import { NavBar } from "../NavBar"
import {
  HeaderContainer,
  HeaderText,
  DiscoverButton,
  HeroImage,
  HeaderSpacer,
} from "./style"
import { scrollTo } from "helpers/scrollTo"

const Header = ({ title, internal, home, img, excerpt }) => {
  const noImg = !img

  return (
    <HeaderContainer noImg={noImg}>
      {img && <HeroImage fluid={img} loading="eager" />}
      <NavBar noImg={noImg} />

      {home || title || excerpt ? (
        <HeaderText
          internal={internal}
          excerpt={excerpt}
          noImg={noImg}
          home={home}
          title={title}
        >
          {title && <h1 dangerouslySetInnerHTML={{ __html: title }} />}

          {(home && excerpt) || (internal && noImg && excerpt) ? (
            <p>{excerpt}</p>
          ) : null}
        </HeaderText>
      ) : (
        <HeaderSpacer />
      )}

      {home && (
        <DiscoverButton onClick={() => scrollTo("discover-here")}>
          Discover our work
        </DiscoverButton>
      )}
    </HeaderContainer>
  )
}

export default Header
