import React from "react"

import { SocialLinksContainer } from "./style.js"

import { ReactComponent as FacebookLogo } from "images/icons/facebook.svg"
import { ReactComponent as InstagramLogo } from "images/icons/instagram.svg"
import { ReactComponent as LinkedIn } from "images/icons/linked-in.svg"

const SocialLinks = props => {
  return (
    <SocialLinksContainer footer={props.footer} className={"social-links"}>
      <a
        href="https://www.instagram.com/voice_brandagency/"
        target="_blank"
        rel="noopener noreferrer"
        className="instagram"
      >
        <InstagramLogo />
      </a>
    
      <a
        href="https://www.linkedin.com/company/voice-brand-agency/?originalSubdomain=au"
        target="_blank"
        rel="noopener noreferrer"
        className="linked-in"

      >
        <LinkedIn />
      </a>

      <a
        href="https://www.facebook.com/VoiceBrandAgency/"
        target="_blank"
        rel="noopener noreferrer"
        className="facebook"

      >
        <FacebookLogo />
      </a>
    </SocialLinksContainer>
  )
}

export default SocialLinks
