import React from "react"
import { ReactComponent as Art } from "../../images/icons/art.svg"
import { ReactComponent as Search } from "../../images/icons/search.svg"
import { ReactComponent as MagnifyingGlass } from "../../images/icons/magnifying-glass.svg"
import { ReactComponent as IndustrialDesign } from "../../images/icons/industrial_design.svg"
import { ReactComponent as MailIcon } from "../../images/icons/mail.svg"
import { ReactComponent as MessageIcon } from "../../images/icons/message.svg"
import { ReactComponent as TitleIcon } from "../../images/icons/title.svg"
import { ReactComponent as NameIcon } from "../../images/icons/name.svg"
import { ReactComponent as PhoneIcon } from "../../images/icons/phone.svg"
import { ReactComponent as ArrowLeft } from "../../images/icons/arrow-left.svg"
import { ReactComponent as RightArrow } from "../../images/icons/right-arrow.svg"
import { ReactComponent as VideoPlay } from "../../images/icons/video-paly.svg"
import { ReactComponent as ArrowDown } from "../../images/icons/arrow_down.svg"
import { ReactComponent as Contact } from "../../images/icons/contact.svg"

const iconMap = {
  art: { icon: Art },
  "industrial-design": { icon: IndustrialDesign },
  search: { icon: Search },
  magnifyingGlass: { icon: MagnifyingGlass },
  mail: { icon: MailIcon },
  message: { icon: MessageIcon },
  title: { icon: TitleIcon },
  name: { icon: NameIcon },
  phone: { icon: PhoneIcon },
  "arrow-left": { icon: ArrowLeft },
  "right-arrow": { icon: RightArrow },
  "video-play": { icon: VideoPlay },
  arrow_down: { icon: ArrowDown },
  contact: { icon: Contact },
}

export const hasIcon = name => {
  return !!iconMap[name]
}

export const Icon = ({ name, className }) => {
  return iconMap[name]
    ? React.createElement(iconMap[name].icon, { className: className || name })
    : false
}
